exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-templates-articles-template-jsx": () => import("./../../../src/templates/ArticlesTemplate.jsx" /* webpackChunkName: "component---src-templates-articles-template-jsx" */)
}

